<template>
  <div class="main-container">
    <h1 class="main-heading">{{$t('SPAWNER')}}</h1>
    <div class="header-container" v-if="spawnerObj.spawners">
      <div class="form-container" >
        <p>{{ $t(`ARENA`) }}</p>
        <select v-model="spawnerIndex">
          <option v-for="index in spawnerObj.spawners.length" v-bind:key="index" :value="index - 1">{{ index}}</option>
        </select>
      </div>
      <div class="form-container" >
        <p>{{ $t(`NAME`) }}</p>
        <input v-model="spawnerObj.spawners[spawnerIndex].name"/>
      </div>
      <button class="add-button success" @click="addArena">{{ $t('ADD_ARENA') }}</button>
    </div>
    <div class="content-container" v-if="spawnerObj.spawners">
      <div class="col" v-for="(category, i) in Object.keys(spawnerObj.spawners[spawnerIndex]).filter(x => !['id', 'name', 'critical_rate', 'coop'].includes(x)).sort()" v-bind:key="i">
        <div class="col-title">{{ $t(category) }}</div>
        <div class="category-section" v-for="categoryKey in Object.keys(spawnerObj.spawners[spawnerIndex][category]).filter(x => Object.keys(spawnerObj.spawners[spawnerIndex][category][x]).length > 0).sort()" v-bind:key="categoryKey">
          <div class="category-title">{{ $t(categoryKey) }}</div>
          <div class="content-row" v-for="(key, index) in Object.keys(spawnerObj.spawners[spawnerIndex][category][categoryKey]).filter(x => x != 'mob_spawn_end_cooldowns').sort()" v-bind:key="key" :class="index % 2 === 0 ? 'alternate' : ''">
            <div v-if="key === 'mob_spawn_start_cooldowns'" class="sub-table">
              <div class="row-title">{{ $t(`DB_VALUES.${key}`)}}</div>
              <div></div>
              <div v-for="(elem, index) in spawnerObj.spawners[spawnerIndex][category][categoryKey][key]" v-bind:key="index">
                <div>{{ 'Wave ' + (index + 1) + (index === 5 ? ' +' : '') }}</div>
                <input v-model.number="spawnerObj.spawners[spawnerIndex][category][categoryKey][key][index]" type="number" />
                <input v-model.number="spawnerObj.spawners[spawnerIndex][category][categoryKey]['mob_spawn_end_cooldowns'][index]" type="number" />
              </div>
            </div>
            <template v-else>
              <div class="row-title">{{ $t(`DB_VALUES.${key}`) }}</div>
              <input v-model.number="spawnerObj.spawners[spawnerIndex][category][categoryKey][key]" type="number" />
            </template>

          </div>
        </div>
      </div>
    </div>
    <div class="buttons-section">
      <button class="success" @click="openConfirm">Save</button>
      <button v-if="env === 'staging' || env === 'prod'" class="info" @click="() => exportTo('dev')">Export to Development</button>
      <button v-if="env === 'dev' || env === 'prod'" class="info" @click="() => exportTo('staging')">Export to Staging</button>
      <button v-if="env === 'dev' || env === 'staging'" class="info" @click="() => exportTo('prod')">Export to Production</button>
    </div>
        <ConfirmPopup :popup-open="popupOpen" :text="popupText" @popup-close="popupOpen=false" :type="popupType" :changes="changes" @confirm="submit"/>

  </div>
</template>

<script>
import { watch, ref, computed, onBeforeMount, onUpdated, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import dispatchMap from "@/constants/dispatchMap";
import { io } from "socket.io-client";
import ConfirmPopup from '../../components/common/ConfirmPopup.vue'
import changeDetector from '@/util/changeDetector'

export default {
  name: "Spawners",
  components: {
    ConfirmPopup
  },
  setup() {
    const store = useStore();
    const usedPages = ["spawner"];
    const socket = io(process.env.VUE_APP_BACKEND_URL);
    const popupOpen = ref(false);
    const popupTexts = ["This page is edited by another user. Please refresh before making changes.", "Your changes: "];
    const popupText = ref(popupTexts[0])
    const popupType = ref("refresh")
    const changes = ref([])
    onBeforeMount(() => {
      usedPages.forEach(page => {
          dispatchMap[page].forEach((dispatchStr) => {
            store.dispatch(dispatchStr);
          })
      });
      }
    );
    const spawnerObj = ref({});
    const spawnerIndex = ref(0);
    watch(() => store.getters['spawners/getSpawner'], value => {
      spawnerObj.value = { ...value }
      window.firstSpawner = JSON.parse(JSON.stringify(spawnerObj.value))
    }, { immediate: true })

    onUpdated(() => store.dispatch("loader/loadingStatus", false));
    onBeforeUnmount(() => store.dispatch("loader/loadingStatus", true));

    const changeSpawnerIndex = index => spawnerIndex.value = index;

    const submit = () => {
      store.dispatch('spawners/updateSpawner', spawnerObj.value).then(() => {
        socket.emit("update", {
          socketId: socket.id,
          page: "Spawner",
          data: spawnerObj.value
        });
        popupOpen.value = false;
        changes.value = [];
      })
    }
    socket.on('updated', (data) => {
      if (data.socketId != socket.id && data.page === "Spawner" && data.data){
        popupOpen.value = true;
        popupType.value = "refresh";
        popupText.value = popupTexts[0];
        changes.value = changeDetector.detect(spawnerObj.value, data.data, "spawner")
      }
    })

    const openConfirm = () => {
      popupType.value = "confirm";
      popupOpen.value = true;
      popupText.value = popupTexts[1];
      changes.value = changeDetector.detect(window.firstSpawner, spawnerObj.value, "spawner");
    }

    const addArena = () => {
      try {
        const newIndex = spawnerObj.value.spawners.length;
        spawnerObj.value.spawners = [...spawnerObj.value.spawners,{
          ...spawnerObj.value.spawners[0],
          name: 'Arena ' + (newIndex + 1)
        }]
        spawnerIndex.value = newIndex;
      } catch {
        console.log("error");
      }

    }

    const exportTo = environment => {
      store.dispatch('spawners/exportSpawner', { spawner: spawnerObj.value, environment })
    }

    const appConfig = computed(() => store.getters['auth/getAppConfig'])
    const env = appConfig.value.appEnv || 'dev'

    return {
      spawnerObj,
      submit,
      exportTo,
      env,
      spawnerIndex,
      changeSpawnerIndex,
      addArena,
      popupOpen,
      popupText,
      popupType,
      changes,
      openConfirm
    }
  }
}
</script>

<style scoped>
.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0 30px;
}

.content-container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 15px;
}
.content-row {
  padding: 15px;
  width: calc(100% - 30px);
  display: flex;
  align-items: center;
}

.content-row.alternate {
  background-color: #f8f9fa;
}

.row-title {
  width: 80%;
  text-align: start;
}

.col {
  border: 1px solid black;
}

.col-title {
  padding: 20px 0;
  font-weight: 900;
  font-size: 1.5rem;
  background-color: #e0e0e0;
}

.category-title {
  padding: 20px 0 ;
  font-weight: bold;
  font-size: 1.25rem;
  background-color: #f0f0f0;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

button {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: 1px solid gray;
  padding: 5px 20px;
  margin: 5px 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

button.add-button {
  margin: 25px;
}

button.error {
  background-color: rgba(255, 0, 0, 0.15);
}

button.success {
  background-color: rgba(0, 255, 0, 0.15);
}

button.info {
  background-color: rgba(0, 0, 255, 0.15);
}

.buttons-section {
  display: flex;
  padding: 30px;
  flex-direction: column;
  align-items: stretch;
}

.buttons-section button {
  font-size: 1.25rem;
}

.header-container {
  display: grid;
  grid-template-columns: 5fr 5fr 1fr;
  grid-column-gap: 10px;
}

.form-container {
  display: flex;
  margin: 25px;
  padding: 10px;
  border: 1px solid black;

}

.sub-table {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.form-container p {
  width: 60%;
  text-align: start;
  font-weight: bold;
}
.form-container input, .form-container div, .form-container select {
  width: 40%;

}
</style>